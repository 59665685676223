import { combineReducers } from '@reduxjs/toolkit'

// slice
import IFramePlayer from './slice/IFramePlayer'

const rootReducer = combineReducers({
  IFramePlayer: IFramePlayer.reducer,
})

export type RootState = ReturnType<typeof rootReducer>
export type RootActionsName = 'IFramePlayer'

export const RootActions = {
  IFramePlayer: IFramePlayer.actions,
}

export default rootReducer
