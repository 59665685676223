import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const IFramePlayerSlice = createSlice({
  name: 'IFramePlayer',
  initialState: {
    videoList: [] as any[],
    videoId: '',
  },
  reducers: {
    setVideoList: (state, action: PayloadAction<[]>) => {
      state.videoList = action.payload
    },
    setVideoId: (state, action: PayloadAction<string>) => {
      state.videoId = action.payload
    },
  },
})

export default IFramePlayerSlice
