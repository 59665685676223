import { configureStore, PayloadAction } from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'
import { useDispatch, useSelector, useStore } from 'react-redux'
import rootReducer, { RootState } from './rootReducer'

const store = configureStore({
  reducer: rootReducer,
})

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, PayloadAction<string>>
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type AppStore = typeof store
export const useAppStore = () => useStore<AppStore>()
export const useAppSelector = <T extends keyof RootState>(name: T) =>
  useSelector((state: RootState) => state[name])

export default store
