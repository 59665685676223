import { useMount } from 'ahooks'
import { get, has } from 'lodash'
import { useState } from 'react'
import { useNavigate, Outlet, useLocation } from 'react-router-dom'
import { useAppDispatch } from '../store'
import IFramePlayerSlice from '../store/slice/IFramePlayer'

function GetQueryValue(queryName: string) {
  var query = decodeURI(window.location.search.substring(1))
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == queryName) {
      return pair[1]
    }
  }
  return null
}

const BasicAppScreen = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [visible, setVisible] = useState(false)
  const dispatch = useAppDispatch()
  useMount(() => {
    switch (location.pathname) {
      case '/news':
        if (location.search) {
          navigate(`news${location.search}`)
        } else {
          navigate(`news`)
        }
        break
      case '/IFramePlayer':
        const newWindow = window as any
        if (newWindow?.ReactNativeWebView) {
          //ios can work
          window.addEventListener('message', handleMessage)
          //android can work
          document.addEventListener('message', handleMessage)
        }
        navigate(`IFramePlayer`)
        break
      default:
        const id = GetQueryValue('id')
        if (id) {
          navigate(`TheftReport/${id}`)
        } else {
          navigate(`Geofencing`)
        }
        break
    }
    setVisible(true)
  })

  const handleMessage = (event: any) => {
    if (typeof event.data === 'string') {
      try {
        const data = JSON.parse(event.data || {})
        if (has(data, 'videoIdList')) {
          const id = get(data, 'id')
          const videoIdList: [] = get(data, 'videoIdList', [])
          dispatch(IFramePlayerSlice.actions.setVideoList(videoIdList))
          dispatch(IFramePlayerSlice.actions.setVideoId(id))
        }
      } catch (error) {
        console.error('Error parsing event data:', error)
      }
    }
  }

  if (!visible) return null
  return <Outlet />
}

export default BasicAppScreen
